import { graphql, useStaticQuery } from "gatsby";

type Post = {
  id: string;
  title: string;
  description: string;
  slug: string;
  date: string;
  dateISO: string;
  link: string;
  tags: string[];
  blogContent: string;
  internalLinks: string[];
};

type UseAllPostsProps = {
  allMyNodeType: {
    nodes: Post[];
  };
};

const useAllPosts = () => {
  const data = useStaticQuery<UseAllPostsProps>(graphql`
    query MyNodeTypeQuery {
      allMyNodeType {
        nodes {
          id
          title
          description
          date
          link
          tags
          blogContent
          internalLinks
        }
      }
    }
  `);

  return data.allMyNodeType.nodes;
};

export default useAllPosts;
